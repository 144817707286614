<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Fiches groupe</v-card-title>

      <v-row class="mb-5">
        <v-col
          class="tags-search ml-5"
          cols="2"
          sm="2"
        >
          <vue-tags-input
            v-model="tag"
            :tags="tags"
            placeholder="Recherche"
            @tags-changed="newTags => {
              tags = newTags
              search()
            }"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-btn
            class="mt-1"
            small
            color="info"
            @click="search()"
          >
            <v-icon
              small
              class="mr-1"
            >
              {{ icons.mdiDatabaseSearchOutline }}
            </v-icon>
            <i class="fas fa-search"></i>
            Rechercher
          </v-btn>
          <v-btn
            small
            color="info"
            class="ml-2 mt-1"
            @click="reset()"
          >
            <v-icon
              small
              class="mr-1"
            >
              {{ icons.mdiBackupRestore }}
            </v-icon>
            Réinitialisation
          </v-btn>
        </v-col>
      </v-row>

      <div class="clear"></div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Camping
              </th>
              <th class="text-center text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(contactGroupFormSheet, contactGroupFormSheetIndex) in contactGroupFormSheets"
              :key="'e' + contactGroupFormSheetIndex"
            >
              <td class="font-weight-bold">
                {{ contactGroupFormSheet.name }}
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      :to="{ name: 'contactGroupFormSheetDetails', params: { contactGroupFormSheetId: contactGroupFormSheet.id }}"
                    >
                      <v-icon dark>
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!contactGroupFormSheets.length">
            <tr>
              <td>
                <span v-if="loading === false">Aucun éléments.</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-col>
</template>

<script>
import { mdiPlay, mdiPen, mdiEye, mdiTrashCan, mdiBackupRestore, mdiDatabaseSearchOutline } from '@mdi/js'
import Vue from 'vue'
import config from '../../../config'
import Loader from '@/components/Common/Loader'
import VueTagsInput from '@johmun/vue-tags-input'

export default {
  components: {
    Loader,
    VueTagsInput
  },
  data: () => ({
    nextPage: 1,
    tag: '',
    tags: [],
    contactGroupFormSheets: [],
    contactGroupFormSheetToDelete: null,
    deleteGroupFormSheetDialogState: false,
    loading: false,
    icons: {
      mdiPlay,
      mdiPen,
      mdiEye,
      mdiTrashCan,
      mdiBackupRestore,
      mdiDatabaseSearchOutline
    }
  }),
  computed: {
    isHandlingContactGroupFormSheetScroll () {
      return this.$store.getters.isHandlingContactGroupFormSheetScroll
    }
  },
  created () {
    this.getGroupFormSheets()

    window.removeEventListener('scroll', this.handleScroll)

    setTimeout(() => {
      window.addEventListener('scroll', this.handleScroll)
    }, 1000)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const wrapperOffsetHeight = document.querySelector('.v-application--wrap').offsetHeight
      const bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === wrapperOffsetHeight

      if (bottomOfWindow && !this.isHandlingContactGroupFormSheetScroll) {
        this.getGroupFormSheets()
      }
    },
    getGroupFormSheets () {
      this.loading = true

      const params = {
        page: this.nextPage
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      this.$store.commit('setHandlingContactGroupFormSheetScroll', true)

      Vue.prototype.$http
        .get(config.apiUrl + '/api/contact/group-form-sheets', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const contactGroupFormSheets = response.data

            if (contactGroupFormSheets && contactGroupFormSheets.length) {
              this.contactGroupFormSheets.push(...contactGroupFormSheets)

              this.nextPage = this.nextPage + 1
            }
          }

          this.loading = false
          this.$store.commit('setHandlingContactGroupFormSheetScroll', false)
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
          this.$store.commit('setHandlingContactGroupFormSheetScroll', false)
        })
    },
    search () {
      const params = {
        page: 1
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/contact/group-form-sheets', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            this.contactGroupFormSheets = response.data

            this.nextPage = 2
          }
        })
        .catch(() => {
        })
    },
    reset () {
      this.tag = ''
      this.tags = []

      this.search()
    },
    resolveAttrValue (data, attr) {
      const translations = data.translations

      if (translations.fr) {
        return translations.fr[attr]
      }

      return '-'
    }
  }
}
</script>

